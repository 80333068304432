<template>
    <CSDialog :dialog-visible="visible" @onClose="closeModal" @onConfirm="perviewService"
        :dialog-title="`${itemId ? '修改' : '添加'}上门服务`" dialog-width="1100px"
    >
        <template v-slot:dialog-content>
            <div class="edit-visiting-modal-content">
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        项目名称
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <input
                            type="text"
                            placeholder="限10个字"
                            maxlength="10"
                            v-model="serviceInfo.name"
                        />
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        项目描述
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <input
                            type="text"
                            placeholder="限30个字"
                            maxlength="30"
                            v-model="serviceInfo.depict"
                        />
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        项目配图
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <div
                            v-for="(src, imgIndex) in serviceInfo.photos"
                            :key="src"
                            class="photo-view"
                        >
                            <img :src="src" />
                            <i
                                class="icon-close"
                                @click="deleteServiceInfoPhotos(imgIndex)"
                            ></i>
                        </div>

                        <div
                            class="upload-photo"
                            @click="triggerChoosePhoto"
                            v-if="serviceInfo.photos.length < 5"
                        >
                            <div class="text-center">
                                <img
                                    src="../assets/upload.png"
                                    alt=""
                                    style="margin: 5px auto"
                                />
                                <p>上传照片</p>
                            </div>
                        </div>
                        <input
                            type="file"
                            class="file"
                            accept="images/*"
                            id="uploadPhoto"
                            hidden
                            @change="choosePhoto($event)"
                        />
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        项目价格
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <input
                            type="text"
                            style="width: 130px"
                            placeholder="1-999999"
                            v-model="serviceInfo.price"
                        />
                        元/人(次)
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        库存
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <input
                            placeholder="1-999"
                            maxlength="3"
                            style="width: 130px"
                            v-model="serviceInfo.stock"
                        />
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        服务地点
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <input
                            type="text"
                            placeholder="限30个字"
                            maxlength="30"
                            v-model="serviceInfo.serviceLocation"
                        />
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        服务日期
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <div>
                            <input
                                type="radio"
                                v-model="serviceInfo.serviceDateType"
                                id="week-radio"
                                :value="1"
                            />
                            <label
                                for="week-radio"
                                class="mr-2"
                                style="margin-left: 10px"
                                >每周</label
                            >
                            <input
                                type="radio"
                                v-model="serviceInfo.serviceDateType"
                                id="radio"
                                :value="2"
                                style="margin: 0 15px"
                            />
                            <label for="day-radio">指定日期</label>
                        </div>
                        <template v-if="serviceInfo.serviceDateType === 1">
                            <div
                                v-for="(date, n) in serviceInfo.serviceDate"
                                :key="n"
                                class="service-date-item"
                            >
                                <CSSelect
                                    height="40px"
                                    style="margin-right: 10px" i-width="36px"
                                >
                                    <select
                                        v-model="date.week"
                                        :style="{
                                            color:
                                                serviceInfo.commissionScale !=
                                                ''
                                                    ? '#000'
                                                    : '#999',
                                        }"
                                    >
                                        <option value="" disabled>
                                            请选择
                                        </option>
                                        <option
                                            v-for="week in 7"
                                            :value="week"
                                            :key="week"
                                            style="color: #000"
                                        >
                                            {{ weekMapping[week] }}
                                        </option>
                                    </select>
                                </CSSelect>
                                时间段:
                                <el-time-select
                                    v-model="date.startTime"
                                    style="margin-right: 15px"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '00:30',
                                        end: '23:59',
                                    }"
                                    placeholder="请选择"
                                >
                                </el-time-select>
                                <el-time-select
                                    v-model="date.endTime"
                                    placeholder="请选择"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '00:30',
                                        end: '23:59',
                                    }"
                                >
                                </el-time-select>
                                <i
                                    v-if="serviceInfo.serviceDate.length > 1"
                                    class="el-icon-circle-close"
                                    @click="deleteServicedateItem(n)"
                                ></i>
                            </div>
                        </template>
                        <template v-if="serviceInfo.serviceDateType === 2">
                            <div
                                v-for="(date, n) in serviceInfo.serviceDate"
                                :key="n"
                                class="service-date-item"
                            >
                                <CSSelect
                                    style="margin-right: 10px"
                                    height="40px" i-width="36px"
                                >
                                    <el-date-picker
                                        v-model="date.date"
                                        value-format="yyyy-MM-dd"
                                        format="yyyy-MM-dd"
                                        type="date"
                                        style="width: 200px;font-size: 24px;"
                                        placeholder="选择日期"
                                        :editable="false"
                                        prefix-icon="el-icon-time"
                                    >
                                    </el-date-picker>
                                </CSSelect>
                                时间段:
                                <el-time-select
                                    v-model="date.startTime"
                                    placeholder="请选择"
                                    style="margin-right: 15px"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '00:01',
                                        end: '23:59',
                                    }"
                                >
                                </el-time-select>
                                <el-time-select
                                    v-model="date.endTime"
                                    placeholder="请选择"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '00:01',
                                        end: '23:59',
                                    }"
                                >
                                </el-time-select>
                                <i
                                    v-if="serviceInfo.serviceDate.length > 1"
                                    class="el-icon-circle-close"
                                    @click="deleteServicedateItem(n)"
                                ></i>
                            </div>
                        </template>
                        <button
                            class="btn btn-primary"
                            @click="addServiceDate"
                            style="
                                width: 180px;
                                height: 40px;
                                line-height: 1;
                                font-size: 24px;
                            "
                        >
                            添加{{
                                serviceInfo.serviceDateType === 1
                                    ? "每周几"
                                    : "指定日期"
                            }}
                        </button>
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        商户名称
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <input
                            type="text"
                            placeholder="限30个字"
                            maxlength="30"
                            v-model="serviceInfo.merchantName"
                        />
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        联系人
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <input
                            type="text"
                            placeholder="限10个字"
                            maxlength="10"
                            v-model="serviceInfo.merchantContact"
                        />
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        手机号【账号】
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <input
                            type="text"
                            placeholder="限11位数字"
                            maxlength="11"
                            v-model="serviceInfo.merchantPhone"
                        />
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        抽成比例
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <CSSelect height="40px">
                            <select
                                v-model="serviceInfo.commissionScale"
                                :style="{
                                    color:
                                        serviceInfo.commissionScale != ''
                                            ? '#444'
                                            : '#999',
                                }"
                            >
                                <option value="" disabled>请选择</option>
                                <option
                                    v-for="n in 100"
                                    :key="n"
                                    style="color: #444"
                                >
                                    {{ n }}
                                </option>
                            </select>
                        </CSSelect>
                        %
                    </div>
                </div>
                <div class="edit-visiting-modal-content-item">
                    <div class="edit-visiting-modal-content-item-label">
                        项目介绍
                    </div>
                    <div class="edit-visiting-modal-content-item-content">
                        <WangEditor
                            parentComponent="editVisitingService"
                        ></WangEditor>
                    </div>
                </div>
            </div>
        </template>
    </CSDialog>
</template>

<script>
import WangEditor from "@/components/WangEditor";
import { queryVisitingServiceItemDetailUrl } from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";

export default {
    name: "EditVisitingServiceItem",
    components: {
        WangEditor,
        CSSelect,
        CSDialog,
    },
    props: {
        itemId: Number,
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            weekMapping: {
                1: "每周一",
                2: "每周二",
                3: "每周三",
                4: "每周四",
                5: "每周五",
                6: "每周六",
                7: "每周日",
            },
            serviceInfo: {
                regionId: this.$vc.getCurrentRegion().communityId,
                name: "",
                photos: [],
                price: "",
                stock: "",
                depict: '',
                serviceLocation: "",
                serviceDateType: 1,
                serviceDate: [
                    {
                        week: 1,
                        startTime: "",
                        endTime: "",
                    },
                ],
                merchantName: "",
                merchantContact: "",
                merchantPhone: "",
                commissionScale: "",
                introduction: "",
            },
            week: 1,
        };
    },
    watch: {
        visible(val) {
            if (val) {
                if (this.itemId) {
                    this.getServiceInfoDetail(this.itemId);
                } else {
                    this.serviceInfo = {
                        regionId: this.$vc.getCurrentRegion().communityId,
                        name: "",
                        photos: [],
                        price: "",
                        stock: "",
                        serviceLocation: "",
                        serviceDateType: 1,
                        serviceDate: [
                            {
                                week: 1,
                                startTime: "",
                                endTime: "",
                            },
                        ],
                        merchantName: "",
                        merchantContact: "",
                        merchantPhone: "",
                        commissionScale: "",
                        introduction: "",
                    };
                }
            }
        },
        "serviceInfo.serviceDateType": function (val, oldVal) {
            let defaultOptions;
            if (this.serviceInfo.serviceDateType === 1) {
                defaultOptions = {
                    week: 1,
                    startTime: "",
                    endTime: "",
                };
            } else {
                defaultOptions = {
                    date: "",
                    startTime: "",
                    endTime: "",
                };
            }
            this.serviceInfo.serviceDate = [defaultOptions];
        },
    },
    mounted() {
        this.$vc.on(this.$route.path, "editVisitingService", "changeEditor", this.changeEditor);
    },
    methods: {
        /**
         * 删除图片
         * @param {Number} index 图片下标
         *  */
        deleteServiceInfoPhotos(index) {
            this.serviceInfo.photos.splice(index, 1);
        },
        /**
         * 删除服务日期项
         * @param {Number} n 要删除得到服务日期项目的下标
         *  */
        deleteServicedateItem(n) {
            this.serviceInfo.serviceDate.splice(n, 1);
        },
        // 获取上门服务详情
        getServiceInfoDetail() {
            this.$fly
                .post(queryVisitingServiceItemDetailUrl, {
                    regionId: this.$vc.getCurrentRegion().communityId,
                    itemsId: this.itemId,
                })
                .then((res) => {
                    const data = res.data;
                    data.price = data.price;
                    data.serviceDate = JSON.parse(data.serviceDate);
                    this.$vc.emit(this.$route.path,
                        "wangEditor",
                        "setWangEditorContent",
                        data.introduction
                    );
                    this.$vc.copyObject(data, this.serviceInfo);
                });
        },
        // 预览
        perviewService() {
            const serviceInfo = { ...this.serviceInfo };
            serviceInfo.price = serviceInfo.price;
            if (this.itemId && this.itemId !== "") {
                serviceInfo.itemsId = this.itemId;
            }
            this.$emit("onPreview", serviceInfo);
        },
        // 关闭弹窗
        closeModal() {
            this.$emit("closeModal");
        },
        //  添加服务时间
        addServiceDate() {
            this.week = ++this.week;
            let defaultOptions;
            if (this.serviceInfo.serviceDateType === 1) {
                defaultOptions = {
                    week: this.week,
                    startTime: "",
                    endTime: "",
                };
                if (this.serviceInfo.serviceDate.length >= 7) {
                    this.$vc.message("每周最多只能选择七天");
                    return;
                }
            } else {
                defaultOptions = {
                    date: "",
                    startTime: "",
                    endTime: "",
                };
            }

            this.serviceInfo.serviceDate.push(defaultOptions);
        },
        /**
         * 删除图片
         * @param {Number} index 图片下标
         *
         *  */
        deleteMeetingRoomPhotos(index) {
            this.serviceInfo.photos.splice(index, 1);
        },
        //  调起选择文件
        triggerChoosePhoto() {
            $("#uploadPhoto").trigger("click");
        },
        // 上传图片
        async uploadPhoto(base64) {
            return new Promise((reslove, reject) => {
                this.$vc.http.post(
                    "uploadImg",
                    "upload",
                    {
                        img: base64,
                    },
                    {
                        headres: {
                            "Content-Type": "application/json",
                        },
                    },
                    (resStr, res) => {
                        reslove(res.body.fileSaveName);
                    },
                    (errText, err) => {
                        reject(errText);
                    }
                );
            });
        },
        // 选择文件后处理
       async choosePhoto(event) {
            var photoFiles = event.target.files;
            if (photoFiles && photoFiles.length > 0) {
                // 获取目前上传的文件
                var file = photoFiles[0]; // 文件大小校验的动作
                if (file.size > 1024 * 1024 * 2) {
                    this.$vc.toast("图片大小不能超过 2MB!");
                    return false;
                }
                let formData = new FormData(); //新建FormData对象
                formData.append("files", photoFiles[0]);
                formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
                formData.append("compress", "true");
                const photo = await this.Constants.uploadFileOne(formData);
                if (photo) {
                    this.serviceInfo.photos.push(this.Constants.BUCKET_NAMES.IMG.visitUrl + photo);
                }
            }
        },
        /**
         * 更改富文本编辑器内容
         * @param {String} container 编辑器内容
         *  */
        changeEditor(container) {
            this.serviceInfo.introduction = container;
        },
    },
};
</script>

<style lang="stylus" scoped>
.edit-visiting-modal
    width 1150px
    // height 1190px
    border-radius 10px
    box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
    background #ffffff
    position fixed
    top 50%
    left 50%
    transform translateX(-50%) translateY(-50%)
    &-content
        padding 25px 30px
        box-sizing border-box
        max-height 600px
        overflow auto
        &-item
            font-size 0
            &-label
                display inline-block
                font-size 24px
                text-align right
                width 170px
                margin-right 40px
                vertical-align top
                color #444
            &-content
                font-size 24px
                max-width calc(100% - 170px - 40px)
                vertical-align top
                display inline-block
                color #444
                input[type='text'], input[type='number']
                    padding 0 10px
                    box-sizing border-box
                    width 400px
                    height 40px
                    border-radius 4px
                    border 1px solid #979797
                select
                    height 40px
                    // border-radius 4px
                .service-date-item
                    margin-bottom 14px
                    display flex
                    flex-flow row nowrap
                    align-items center
                    .el-date-editor
                        margin-right 10px
                    .el-date-editor--time-select.el-input--prefix
                        margin-left 10px
                        font-size 24px !important
                        color #444
                        input.el-input__inner
                            font-size 24px !important
                            color #444
                            padding-left 40px
            &:not(:last-of-type)
                margin-bottom 26px
    &-footer
        text-align center
        padding 30px 0
        height 100px
        box-sizing border-box
        border-top 2px solid #f0f0f0
        fon-size 24px
        .btn
            width 80px
            height 40px
            color #ffffff
            &-cancel
                background #FF9F00
            &:not(:last-of-type)
                margin-right 60px
.el-picker-panel.time-select
    .time-select-item
        font-size 24px !important
.cancel
    width 80px
    height 40px
    background #FF9F00
    box-shadow 0px 5px 10px 0px rgba(255, 159, 0, 0.3)
    border-radius 6px
    font-size 24px
    border none
    color #fff
    margin-right 30px
.preview
    width 80px
    height 40px
    background #1ab394
    box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.3)
    border-radius 6px
    border none
    color #fff
    font-size 24px
/deep/.el-input__inner::placeholder
    color #999
</style>
